import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Papa } from 'ngx-papaparse'
import { JobPost } from 'app/shared/models/job'

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private jobMasterList = 'assets/job-sheet.csv'

  private jobList = []

  constructor(private http: HttpClient,private papa: Papa) {
    this.getJobList().subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.parseCSVData(result)
        }
      })
    })
  }

  getJobList() {
    return this.http.get(this.jobMasterList,{responseType: 'text'})
  }

  parseCSVData(csvArray) {
    this.jobList = []

    const tempList = {}


    let headers = csvArray[0]

    for (let i = 1; i < csvArray.length; i++) {

      let obj = new JobPost()
      let currentline = csvArray[i]

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].toLowerCase().trim()] = currentline[j].trim()
      }

      const eligible = JSON.parse(obj.availability.toLowerCase())

      if(!eligible) {
        continue
      }

      obj.availability = eligible;
      this.jobList.push(obj)

    }

  }

  getParsedJobs() {
    return this.jobList
  }
}
