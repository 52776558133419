import { Talent } from 'app/shared/models/talent'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'talentFilter'
})
export class TalentFilterPipe implements PipeTransform {

  transform(talents: Talent[], tags: string[]): Talent[] {

    // Check if is not null
    if (!talents || !tags) return talents

    return talents.filter(talent => tags.every(tag => talent.talent_tag.indexOf(tag) !== -1))
  }

}
