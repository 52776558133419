import { Component, OnInit, HostListener } from '@angular/core'

@Component({
  selector: 'rr-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  private componentName = 'ServicesComponent'

  screenWidth = 0

  microServiceList = [
    {
      name: 'Social Media Marketing',
      img: 'assets/services/icons/social_media_marketing.png',
      idLink: '#marketing'
    },
    {
      name: 'Events Production',
      img: 'assets/services/icons/event_production.png',
      idLink: '#events'
    },
    {
      name: 'Merchandising',
      img: 'assets/services/icons/merchandise.png',
      idLink: '#arts'
    },
    {
      name: 'Live Streaming',
      img: 'assets/services/icons/live_stream.png',
      idLink: '#live'
    },
    {
      name: 'Marketing Campaigns',
      img: 'assets/services/icons/marketing_campaigns.png',
      idLink: '#marketing'
    },
    {
      name: 'Community Management',
      img: 'assets/services/icons/community_management.png',
      idLink: '#community'
    },
    {
      name: 'Digital Art and Design',
      img: 'assets/services/icons/arts_design.png',
      idLink: '#arts'
    },
    {
      name: 'Video Production',
      img: 'assets/services/icons/video_prod.png',
      idLink: '#vidProd'
    },
    {
      name: 'Talents Agency',
      img: 'assets/services/icons/talent_agency.png',
      idLink: '#talents'
    },
    {
      name: 'Studio Space',
      img: 'assets/services/icons/studio_space.png',
      idLink: '#space'
    },
    {
      name: 'Events Space',
      img: 'assets/services/icons/events_place.png',
      idLink: '#space'
    },
    {
      name: 'Community Partnership',
      img: 'assets/services/icons/community_partnership.png',
      idLink: '#community'
    },
    {
      name: 'Esports Tournament Management',
      img: 'assets/services/icons/tourney_manage.png',
      idLink: '#events'
    },
    {
      name: 'Online Advertising',
      img: 'assets/services/icons/online_ad.png',
      idLink: '#marketing'
    },
    {
      name: 'Equipment Rental',
      img: 'assets/services/icons/equipment.png',
      idLink: '#vidProd'
    },
    {
      name: 'Gaming Establishments',
      img: 'assets/services/icons/gaming.png',
      idLink: '#space'
    },
    {
      name: 'Creative Solutions',
      img: 'assets/services/icons/creative.png',
      idLink: '#marketing'
    },
    {
      name: 'Lights and Sounds',
      img: 'assets/services/icons/lights.png',
      idLink: '#events'
    },
  ]

  majorCategories = [
    {
      name: 'Live<br/>Streaming',
      className: 'liveStreaming',
      text: 'New media is currently a massive force in the entertainment industry, capturing a new market untapped by traditional media. Content creators face constant challenges in this ever-changing and competitive landscape. RUMBLE ROYALE offers solutions to powerful hardware, cutting edge broadcasting software, and the best practices in the industry. With the tough stuff out of the way, content creators can focus on producing high-quality and engaging content.',
      imgLink: 'assets/services/cta/live-streaming.png',
      serviceId: 'live',
    },
    {
      name: 'Video<br/>Production',
      className: 'videoProduction',
      text: 'Video production is an integral part of the digital landscape. From coverage for events big and small to productions for innovative concepts, our in-house video crew are veterans at immortalizing moments and communicating ideas.',
      imgLink: 'assets/services/cta/video-prod.png',
      serviceId: 'vidProd',
    },
    {
      name: 'Arts &<br/>Design',
      className: 'artsAndDesign',
      text: 'RUMBLE ROYALE prides itself as a creative collective. Our team of diverse talent share a passion for the industry, bringing innovation and a unique perspective that connects with the community. Artists are important and RUMBLE ROYALE means to show it to the world.',
      imgLink: 'assets/services/cta/art.png',
      serviceId: 'arts',
    },
    {
      name: 'Talent<br/>Agency',
      className: 'talentsAgency',
      text: 'The RUMBLE ROYALE family is diverse and connects many communities and audiences. Household names in the local gaming scene such as Akosi Dogie, Suzzysaur, and KingFB bring the best to the industry with RR. Even mainstream talent like Myrtle Sarrosa, SirRex Kantatero, Eric “Eruption” Tai, and Megan Young have jumped into the streaming industry with us as well. RUMBLE ROYALE’s unique environment, from industry veterans to new talent with diverse audiences, promotes learning from each other and growing with each other.',
      imgLink: 'assets/services/cta/talent-agency.png',
      serviceId: 'talents',
      cta: {
        text: 'see our talents',
        link: '/talents'
      }
    },
    {
      name: 'Marketing',
      className: 'marketing',
      text: 'Our marketing team is always out creating new opportunities for our talents and communicating with the public to build bridges and establish the best relationships. RUMBLE ROYALE has ambassadors for brands such as MSI, RealMe, and Nvidia. We also have several industry partnerships such as with Facebook,  Garena, Netease, and Moonton. RR always aims to create more amazing partnerships and relationships for the community.',
      imgLink: 'assets/services/cta/marketing.png',
      serviceId: 'marketing',
    },
    {
      name: 'Events',
      className: 'events',
      text: 'Great events are the backbone of any great community. RUMBLE ROYALE is no stranger to organizing them. From community events for League of Legends and Rules of Survival, to our other events such as Righteous Glory and tournaments like Mogul All-Stars, we create venues for the community to get together and grow stronger. RR is always looking to help bring the community’s visions and dreams a possibility.',
      imgLink: 'assets/services/cta/events.png',
      serviceId: 'events',
      cta: {
        text: 'check our events',
        link: '/projects'
      }
    },
    {
      name: 'Community',
      className: 'community',
      text: 'RUMBLE ROYALE is a company that was originally bootstrapped and funded by the support of the community that is now 40,000,000 strong. At our core, the community is what drives us and is integral to pushing the industry even further. Our social media team is always on the lookout for the latest updates and trends, in streaming and gaming. Community focus and fostering a good environment is one our pillars that we strive for.',
      imgLink: 'assets/services/cta/community.png',
      serviceId: 'community',
    },
    {
      name: 'Gaming Establishments',
      className: 'space',
      text: 'A good environment and the best facilities cultivate a positive work culture that ensures creativity and innovation. At RUMBLE ROYALE, creating a place like this for our talent, artists, and staff is an utmost priority. The Dungeon houses most of our team, providing a fun work environment and a place for our streamers to come and schedule streams. On the horizon, our Stronghold concept promises to expand upon this and open up the space to more people and possibilities.',
      imgLink: 'assets/services/cta/space.png',
      serviceId: 'space',
      cta: {
        text: 'see our gaming spaces',
        link: '/projects'
      }
    },
  ]

  mobileParts = [
    {
      link: '/streams',
      image: 'assets/services/m/01.jpg',
    },
    {
      link: '/notfound',
      image: 'assets/services/m/02.jpg',
    },
    {
      link: '/notfound',
      image: 'assets/services/m/03.jpg',
    },
    {
      link: '/talents',
      image: 'assets/services/m/04.jpg',
    },
    {
      link: '/notfound',
      image: 'assets/services/m/05.jpg',
    },
    {
      link: '/projects',
      image: 'assets/services/m/06.jpg',
    },
    {
      link: '/notfound',
      image: 'assets/services/m/07.jpg',
    },
    {
      link: '/notfound',
      image: 'assets/services/m/08.jpg',
    },
  ]

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth
  }

  constructor() {
    this.onResize()
  }

  ngOnInit() {
  }

}
