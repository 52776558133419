import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Papa } from 'ngx-papaparse'
import { Talent } from 'app/shared/models/talent'

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  private talentMasterList = 'assets/talent-sheet.csv'

  private talentRoster = null
  private talentTags = null

  constructor(private http: HttpClient, private papa: Papa) {
    this.getTalentRoster().subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.parseCSVData(result)
        }
      })
    })
  }

  getTalentRoster(fileToread = undefined) {
    return this.http.get(fileToread || this.talentMasterList,{responseType: 'text'})
  }

  parseCSVData(csvArray) {
    let result: Talent[] = []
    let tagList: string = 'Male, Female, Gamer, Streamer, Youtuber, Celebrity, Host, Model, Shoutcaster, Cosplayer, Vlogger, Radio DJ, Voice Actor, Artist, Musician, Pro-Player, Tech Reviewer, Dinosaur,'

    let headers = csvArray[0]
    for (let i = 1; i < csvArray.length; i++) {

      let obj = new Talent()
      let currentLine = csvArray[i]

      if(currentLine.length === headers.length) {
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].toLowerCase().trim()] = currentLine[j].trim()
        }

        //Capture the talent_tag property
        tagList += csvArray.length-i == 1 ? obj['talent_tag'] : obj['talent_tag'].concat(',')
        obj['photos'] = obj['photo'].split(',').map(x => x.trim())
        obj['talent_link'] = `/talents/${obj['streamer_name'].replace(/ /g, '').trim()}`
        obj['talent_code'] = `${obj['streamer_name'].replace(/ /g, '').trim()}`
        obj['talent_tag'] = obj['talent_tag'].split(',').map(x => x.trim()).filter(Boolean)
        if(obj['highlights']) obj['highlight_list'] = obj['highlights'].split('.').map(x => x.trim()).filter(Boolean)
        obj['display_name'] = obj['can_use_name'] == 'Yes' ? obj['full_name'] : obj['can_use_name']
        // Remove Full Name
        delete obj['full_name']
        result.push(obj)
      }
    }

    const filteredTags = new Set(tagList.split(',').map(x => x.trim()))

    this.talentTags = filteredTags
    //return result //JavaScript object
    this.talentRoster = result
  }

  getParsedData() {
    return this.talentRoster
  }

  getTalentTags() {
    return this.talentTags
  }

  getTalent(code) {
    return this.talentRoster.find(talent => talent['talent_code'] == code)
  }
}
