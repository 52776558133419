import { Pipe, PipeTransform } from "@angular/core"
import { Talent } from 'app/shared/models/talent'

@Pipe({
  name: "talentSort"
})
export class TalentSortPipe  implements PipeTransform {
  transform(array: any, field: string, order: number = 1): any[] {
    if (!Array.isArray(array)) {
      return
    }

    let returnType = null
    array.sort((a: Talent, b: Talent) => {

      if (a[field] === null || a[field].length === 0) {
          returnType = 1
      }
      else if (b[field] === null || b[field].length === 0) {
          returnType = -1
      }
      else if (a[field] === b[field]) {
          returnType = 0
      }
      else {
        returnType = a[field] < b[field] ? -1 : 1
      }

      return returnType * order

    })
    return array
  }
}