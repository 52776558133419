// Modules
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from 'app/app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PapaParseModule } from 'ngx-papaparse'
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core'
import { NgxPageScrollModule } from 'ngx-page-scroll'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { DragScrollModule } from 'ngx-drag-scroll'
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image'
import { MomentModule } from 'ngx-moment'
import { InViewportModule } from '@thisissoon/angular-inviewport'
import { ScrollSpyModule } from '@thisissoon/angular-scrollspy'
import {enableProdMode} from '@angular/core'
// Pages
import { AppComponent } from 'app/app.component'
import { HeaderComponent } from 'app/shared/header/header.component'
import { SidebarComponent } from 'app/shared/sidebar/sidebar.component'
import { TalentComponent } from 'app/pages/talent/talent.component'
import { NotFoundComponent } from './pages/notfound/notfound.component'
import { ServicesComponent } from './pages/services/services.component'
import { TalentDescriptionComponent } from './pages/talent/talent-description/talent-description.component'
import { GearsComponent } from './pages/gears/gears.component'
import { HomeComponent } from './pages/home/home.component'
import { AboutComponent } from './pages/about/about.component'
import { TimelineComponent } from './pages/timeline/timeline.component'
import { FooterComponent } from './shared/footer/footer.component'

// Services
import { CsvService } from 'app/shared/services/csv.service'
import { EventService } from 'app/shared/services/event.service'
import { JobService } from 'app/shared/services/job.service'

// Pipes
import { TalentFilterPipe } from './shared/pipes/talent-filter.pipe'
import { TalentSearchPipe } from './shared/pipes/talent-search.pipe'
import { TalentSortPipe } from './shared/pipes/talent-image.pipe'
import { SafePipe } from './shared/pipes/safe.pipe'
import { OrderByPipe } from './shared/pipes/order-by.pipe'
import { EventSearchPipe } from './shared/pipes/event-search.pipe'

// Directives
import { ScrollTrackerDirective } from './shared/directives/scroll-tracker.directive'
import { ContactComponent } from './pages/contact/contact.component'
import { CareersComponent } from './pages/careers/careers.component';
import { CareerDescriptionComponent } from './pages/careers/career-description/career-description.component'

enableProdMode()

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    TalentComponent,
    NotFoundComponent,
    TalentDescriptionComponent,
    TalentFilterPipe,
    ServicesComponent,
    TalentSearchPipe,
    EventSearchPipe,
    FooterComponent,
    SafePipe,
    GearsComponent,
    HomeComponent,
    AboutComponent,
    ScrollTrackerDirective,
    TalentSortPipe,
    TimelineComponent,
    OrderByPipe,
    ContactComponent,
    CareersComponent,
    CareerDescriptionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PapaParseModule,
    FormsModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    NgbModule.forRoot(),
    DragScrollModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    MomentModule,
    InViewportModule,
    ScrollSpyModule.forRoot(),
  ],
  providers: [
    CsvService,
    EventService,
    JobService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
