export class Talent {
  streamer_name: string
  full_name: string
  facebook_link: string
  highlight: string
  highlight_list: any
  twitter_link: string
  youtube_link: string
  instagram_link: string
  description: string
  talent_page_img_link: string
  talent_main_img_link: string
  talent_tag: any
  talent_link: string
  hex_bot: string
  hex_top: string
  display_name: string
  photo: any
  photos: any
  fb_likes: number
  twitter_follow: number
  instagram_follow: number
  youtube_subs: number
  twitch_follow: number
}
