import { Component, OnInit, ViewChild } from '@angular/core'
import { ScrollTrackerDirective } from './shared/directives/scroll-tracker.directive'
import { Router, NavigationEnd } from '@angular/router';
declare let gtag: Function;
@Component({
  selector: 'rr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(ScrollTrackerDirective) scrollDirective: any
  title = 'Rumble Royale'

  constructor(public router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-176436194-1', { 'page_path': event.urlAfterRedirects });
      }
    })
  }

  ngOnInit() {
  }

  activePage() {
    return {
      'homeBound': this.isPage('HomeComponent'),
      'serviceBound': this.isPage('ServicesComponent'),
      'talentBound': this.isPage('TalentComponent'),
      'constructionArea': this.isPage('NotFoundComponent'),
      'aboutBound': this.isPage('AboutComponent'),
      'careerBound': this.isPage('CareersComponent')
    }
  }

  private componentLoaded = ''

  isPage(componentName: string) {
    return this.componentLoaded === componentName
  }

  onActivate(e) {
    this.componentLoaded = e.componentName
  }
}
