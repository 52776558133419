import { Component, OnInit, HostListener } from '@angular/core'

@Component({
  selector: 'rr-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  private componentName = 'AboutComponent'

  screenWidth = window.innerWidth

  collective = [
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      title: 'Graphic Artist',
      image: 'assets/about/collective/koj.png'
    },
  ]

  affiliates = [
    {
      name: 'Gamely',
      image: 'assets/about/affiliate/gamely.png',
      expand: true,
    },
    {
      name: 'NetEase Games',
      image: 'assets/about/affiliate/netease.png',
      expand: true,
    },
    {
      name: 'Nvidia',
      image: 'assets/about/affiliate/nvidia.png',
      expand: true,
    },
    {
      name: 'Asus',
      image: 'assets/about/affiliate/asus.png',
      expand: true,
    },
    {
      name: 'Realme',
      image: 'assets/about/affiliate/realme.png',
      smallLogo: true,
    },
    {
      name: 'Huawei',
      image: 'assets/about/affiliate/huawei.png',
    },
    {
      name: 'Palit',
      image: 'assets/about/affiliate/palit.png',
    },
    {
      name: 'Playpark',
      image: 'assets/about/affiliate/playpark.png',
    },
    {
      name: 'OBS',
      image: 'assets/about/affiliate/obs.png',
      smallLogo: true,
    },
    {
      name: 'Miriam College',
      image: 'assets/about/affiliate/miriam.png',
      smallLogo: true,
    },
    {
      name: 'University of the Philippines',
      image: 'assets/about/affiliate/up.png',
      smallLogo: true,
    },
    {
      name: 'Ateneo de Manila',
      image: 'assets/about/affiliate/ateneo.png',
      smallLogo: true,
    },
    {
      name: 'Smart Communications',
      image: 'assets/about/affiliate/smart.png',
    },
    {
      name: 'PLDT',
      image: 'assets/about/affiliate/pldt.png',
    },
    {
      name: 'Globe Telecom',
      image: 'assets/about/affiliate/globe.png',
    },
    {
      name: 'Mineski',
      image: 'assets/about/affiliate/mineski.png',
      smallLogo: true,
    },
    {
      name: 'Minute Burger',
      image: 'assets/about/affiliate/minute-burger.png',
      smallLogo: true,
    },
    {
      name: 'Mobile Legends',
      image: 'assets/about/affiliate/mobile-legends.png',
    },
    {
      name: 'Pacific eSports',
      image: 'assets/about/affiliate/pacific-esports.png',
      smallLogo: true,
    },
    {
      name: 'Project Lupon',
      image: 'assets/about/affiliate/project-lupon.png',
      expand: true,
    },
    {
      name: 'Garena',
      image: 'assets/about/affiliate/garena.png',
      expand: true,
    },
    {
      name: 'Riot Games',
      image: 'assets/about/affiliate/riot.png',
      expand: true,
    },
    {
      name: 'Mineski Events Team',
      image: 'assets/about/affiliate/met.png',
      expand: true,
    },
    {
      name: 'eSports Center',
      image: 'assets/about/affiliate/esc.png',
      smallLogo: true,
    },
    {
      name: 'Corsair',
      image: 'assets/about/affiliate/corsair.png',
      smallLogo: true,
    },
    {
      name: 'Coca-Cola',
      image: 'assets/about/affiliate/coca-cola.png',
      smallLogo: true,
    },
    {
      name: 'Aorus',
      image: 'assets/about/affiliate/aorus.png',
    },
    {
      name: 'AMD',
      image: 'assets/about/affiliate/amd.png',
    },
    {
      name: 'Gigabyte',
      image: 'assets/about/affiliate/gigabyte.png',
    },
    {
      name: 'CodaShop',
      image: 'assets/about/affiliate/codashop.png',
      smallLogo: true,
    },
    {
      name: 'Kumu',
      image: 'assets/about/affiliate/kumu.png',
      smallLogo: true,
    },
    {
      name: 'Twitch',
      image: 'assets/about/affiliate/twitch.png',
      smallLogo: true,
    },
    {
      name: 'Xsplit',
      image: 'assets/about/affiliate/xsplit.png',
    },
    {
      name: 'Razer',
      image: 'assets/about/affiliate/razer.png',

    },
    {
      name: 'Bacchus',
      image: 'assets/about/affiliate/bacchus.png',
      smallLogo: true,
    },
    {
      name: 'Honor',
      image: 'assets/about/affiliate/honor.png',
      smallLogo: true,
    },
    {
      name: 'Monster Energy',
      image: 'assets/about/affiliate/monster-energy.png',
    },
    {
      name: 'Oppo',
      image: 'assets/about/affiliate/oppo.png',
    },
    {
      name: 'Green Sun',
      image: 'assets/about/affiliate/green-sun.png',
    },
    {
      name: 'Execration',
      image: 'assets/about/affiliate/execration.png',
      smallLogo: true,
    },
    {
      name: 'Aether Main',
      image: 'assets/about/affiliate/aether.png',
      smallLogo: true,
    },
    {
      name: 'Aether Atlas',
      image: 'assets/about/affiliate/atlas.png',
      smallLogo: true,
    },
    {
      name: 'MSI',
      image: 'assets/about/affiliate/msi.png',
      expand: true,
    },
    {
      name: 'Facebook Gaming',
      image: 'assets/about/affiliate/fbgg.png',
      expand: true,
    },
    {
      name: 'Complink',
      image: 'assets/about/affiliate/complink.png',
      expand: true,
    },
    {
      name: 'Logitech G',
      image: 'assets/about/affiliate/logitech.png',
      expand: true,
    },
    {
      name: 'SM Cinema',
      image: 'assets/about/affiliate/sm-cinema.png',
      smallLogo: true,
    },
    {
      name: 'Moonton',
      image: 'assets/about/affiliate/moonton.png',
      smallLogo: true,
    },
    {
      name: 'Tamago',
      image: 'assets/about/affiliate/tamago.png',
      smallLogo: true,
    },
    {
      name: 'Game Over PH',
      image: 'assets/about/affiliate/gameover.png',
    },
    {
      name: 'PUBG',
      image: 'assets/about/affiliate/pubg.png',
    },
  ]

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth
  }

  constructor() { }

  ngOnInit() {
  }


}
