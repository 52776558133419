import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Papa } from 'ngx-papaparse'
import { Event } from 'app/shared/models/event'

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventMasterList = 'assets/event-sheet.csv'

  private eventList = []
  private eventCount = 0

  constructor(private http: HttpClient,private papa: Papa) {
    this.getEventList().subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.parseCSVData(result)
        }
      })
    })
  }

  getEventList() {
    return this.http.get(this.eventMasterList,{responseType: 'text'})
  }

  parseCSVData(csvArray) {
    this.eventList = []
    this.eventCount = 0
    const tempList = {}

    let headers = csvArray[0]
    this.eventCount = csvArray.length - 1
    for (let i = 1; i < csvArray.length; i++) {

      let obj = new Event()
      let currentline = csvArray[i]

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].toLowerCase().trim()] = currentline[j].trim()
      }

      const groupText = obj.year

      if(tempList[groupText]) {
        tempList[groupText].push(obj)
      } else {
        tempList[groupText] = [obj]
      }
    }

    // Normalize
    Object.keys(tempList).map((year) => {
      this.eventList.push({
        year: year,
        events: tempList[year]
      })
    })
  }

  getParsedEvent() {
    return this.eventList
  }

  getEventCount() {
    return this.eventCount
  }
}
