import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { NotFoundComponent } from 'app/pages/notfound/notfound.component'
import { TalentComponent } from 'app/pages/talent/talent.component'
import { TalentDescriptionComponent } from 'app/pages/talent/talent-description/talent-description.component'
import { ServicesComponent } from 'app/pages/services/services.component'
import { GearsComponent } from 'app/pages/gears/gears.component'
import { HomeComponent } from 'app/pages/home/home.component'
import { AboutComponent } from 'app/pages/about/about.component'
import { TimelineComponent } from 'app/pages/timeline/timeline.component'
import { ContactComponent } from 'app/pages/contact/contact.component'
import { CareersComponent } from 'app/pages/careers/careers.component'

const routes: Routes = [
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'services', component: ServicesComponent
  },
  {
    path: 'talents/:link', component: TalentComponent
  },
  {
    path: 'talents', component: TalentComponent
  },
  {
    path: 'projects', component: TimelineComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'careers', component: CareersComponent
  },
  {
    path: '', component: HomeComponent
  },
  {
    path: '**', component: NotFoundComponent
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
