import { Event } from 'app/shared/models/event'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'eventSearch'
})
export class EventSearchPipe implements PipeTransform {

  transform(events: Event[], search: string): Event[] {
    // Check if is not null
    if (!events || !search) return events

    return events.filter(
      rrEvent => rrEvent.name.toLowerCase().concat(rrEvent.description.toLowerCase()).indexOf(search.toLowerCase()) !== -1
    )
  }
}
