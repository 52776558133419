import { Component, OnInit, HostListener } from '@angular/core'

@Component({
  selector: 'rr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  links = [
    {
      text: 'About Us',
      path: '/about',
    },
    {
      text: 'Services',
      path: '/services',
    },
    {
      text: 'Projects',
      path: '/projects',
    },
    {
      text: 'Talents',
      path: '/talents'
    },
    {
      text: 'Careers',
      path: '/careers',
    },
    {
      text: 'Contact Us',
      path: '/contact',
      footerNotes: true
    }, 
  ]

  screenHeight = 0
  screenWidth = 0
  logoLink = 'assets/common/rr-logo.png'
  isNavbarCollapsed = true

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenHeight = window.innerHeight
     this.screenWidth = window.innerWidth

     this.logoLink =  this.screenWidth <= 1000 ? 'assets/common/rr-logo-mobile.png' : 'assets/common/rr-logo.png'
  }

  constructor() {
    this.onResize()
  }

  ngOnInit() {
  }

  toggleCollapse(toggleState: boolean = null) {

    this.isNavbarCollapsed = toggleState ? toggleState : !this.isNavbarCollapsed  
  }
  
}
