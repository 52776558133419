import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[scrollTracker]'
})
export class ScrollTrackerDirective {

  private currentPosition: number = 0

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    // do tracking
    // console.log('scrolled', event.target.scrollTop)
    // Listen to click events in the component
    let tracker = event.target    
    this.currentPosition = event.target.scrollTop
  }

  hasScrolled() {
    return this.currentPosition !== 0
  }

  constructor() { }

}
