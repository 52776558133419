import { Component, OnInit, HostListener } from '@angular/core'
import { trigger, style, state, transition, animate, group } from '@angular/animations'
import { Location } from "@angular/common"
import { Papa } from 'ngx-papaparse'
import { Talent } from 'app/shared/models/talent'
import { CsvService } from 'app/shared/services/csv.service'
import { ActivatedRoute } from "@angular/router"

@Component({
  selector: 'rr-talent',
  templateUrl: './talent.component.html',
  styleUrls: ['./talent.component.scss']
})
export class TalentComponent implements OnInit {

  private componentName = 'TalentComponent'

  sortList = [
    // {
    //   value: 'streamer_name',
    //   ascImg: 'assets/talent/sort/a-z.png',
    //   descImg: 'assets/talent/sort/z-a.png'
    // },
    {
      value: 'fb_likes',
      ascImg: 'assets/talent/sort/facebook.png',
    },
    {
      value: 'twitter_follow',
      ascImg: 'assets/talent/sort/twitter.png',
    },
    {
      value: 'instagram_follow',
      ascImg: 'assets/talent/sort/instagram.png',
    },
    {
      value: 'youtube_subs',
      ascImg: 'assets/talent/sort/youtube.png',
    },
    {
      value: 'twitch_follow',
      ascImg: 'assets/talent/sort/twitch.png',
    },
  ]

  showFilters = true
  talentRoster = []
  talentTags: string[] = []
  filterTags: string[] = []
  order: number = -1 // 1 asc, -1 desc
  orderField: string = '-weight'
  path: string[] = ['-weight']
  search: string = null
  sortTag = 'thumbnail'
  selectedTalent = null
  code = null
  screenWidth = 0

  constructor(
    private cs: CsvService,
    private papa: Papa,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.onResize()
  }

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("link")
    this.cs.getTalentRoster().subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.cs.parseCSVData(result.data)
          this.talentTags = this.cs.getTalentTags()
          this.talentRoster = this.cs.getParsedData()

          if(this.screenWidth < 1000) {
            this.toggleFilterList()
          }

          // Check if URL accessed Directly
          if(this.code) this.selectedTalent = this.cs.getTalent(this.code)
        }
      })
    })
  }

  getData() {
    return this.talentRoster
  }

  sortTable(prop: string, order: number = null) {


    if(this.path[0].replace('-','') === prop.split('.')[0].replace('-','')) {
      this.orderField= '-weight'
      this.path = ['-weight']
      this.order = -1
      return false
    }

    if(order) {
      this.order = order
    }

    this.orderField = this.order === -1 ? `-${prop}` : prop
    this.path = [this.orderField]

    return false // do not reload
  }

  toggleFilterList() {
    this.showFilters = !this.showFilters
    return false // do not reload
  }

  toggleFilterTag(value) {
    if(this.filterTags.includes(value)) {
      this.filterTags = this.filterTags.filter(tag => tag != value)
    } else {
      this.filterTags = this.filterTags.concat([value])
    }

    return false
  }

  hasFiltersActive() {
    return this.filterTags.length != 0
  }

  resetFilters() {
    this.filterTags = []
    return false
  }

  isSelectedFilter(value) {
    return value === this.orderField.replace('-','')
  }

  changeOrder() {
    this.order = this.order * (-1)
    this.orderField = this.order === -1 ? `-${this.orderField}` : this.orderField.replace('-','')
    return false
  }

  setSelectedTalent(talent: Talent) {
    this.selectedTalent = talent
    return false
  }

  removeSelectedTalent(event: any) {
    this.selectedTalent = null
    this.location.go('/talents')
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth
  }
}
