import { Component, OnInit } from '@angular/core'
import { CsvService } from 'app/shared/services/csv.service'
import { JobService } from 'app/shared/services/job.service'
import { Talent } from 'app/shared/models/talent'
import { Papa } from 'ngx-papaparse'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'rr-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  private componentName = 'CareersComponent'

  jobList = []
  showOverLay = false
  lvl1 = {
    name: null,
    mail: null
  }

  constructor(
    private cs: CsvService,
    private js: JobService,
    private papa: Papa,
    private http: HttpClient
  ) { }

  private talentMasterList = 'assets/rr-faces-sheet.csv'
  talentRoster = null
  selectedTalent = null

  ngOnInit() {
    this.js.getJobList().subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.js.parseCSVData(result.data)
          this.jobList = this.js.getParsedJobs()
        }
      })
    })

    this.cs.getTalentRoster(this.talentMasterList).subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.cs.parseCSVData(result.data)
          this.talentRoster = this.cs.getParsedData()
        }
      })
    })
  }

  invalidData(obj) {
    for(var key in obj) {
      if(obj[key] === null || obj[key] === '')
        return true
    }
    return false
  }

  sendGuide() {

    if(this.invalidData(this.lvl1)) {
      alert('Please fill in all details')
      return false
    }

    let body = new FormData()
    Object.keys(this.lvl1).map((key) => body.append(key, this.lvl1[key]))

    this.http
      .post('/newbie.php', body)
      .subscribe((res) => {
        this.lvl1 = {
          name: null,
          mail: null,
        }

        alert('Thanks for submitting! Please check your email now.')
      })

    this.showOverLay = false
  }


  talentSamples = [
    {
      name: 'Myrtle Sarrosa',
      subText: 'Celebrity',
      image: 'assets/careers/myrtle.png',
      link: '/talents/Myrtle',
    },
    {
      name: 'Suzzysaur',
      subText: 'Streamer',
      image: 'assets/careers/suzzy.png',
      link: '/talents/Suzzysaur',
    },
    {
      name: 'Eruption',
      subText: 'Celebrity',
      image: 'assets/careers/eruption.png',
      link: '/talents/Eruption',
    },
    {
      name: 'KingFB',
      subText: 'Streamer',
      image: 'assets/careers/royce.png',
      link: '/talents/KingFB',
    },
    {
      name: 'AkosiDogie',
      subText: 'Streamer',
      image: 'assets/careers/dogie.png',
      link: '/talents/AkosiDogie',
    },
    {
      name: 'Megan',
      subText: 'Celebrity',
      image: 'assets/careers/megan.png',
      link: '/talents/MeganYoung',
    },
    {
      name: 'Chooxtv',
      subText: 'Streamer',
      image: 'assets/careers/choox.png',
      link: '/talents/ChooxTV',
    },
    {
      name: 'Ian Tayao',
      subText: 'Rock icon',
      image: 'assets/careers/ian.png',
      link: '/talents/IanTayao',
    },
    {
      name: 'Charess',
      subText: 'Cosplayer',
      image: 'assets/careers/charess.png',
      link: '/talents/Charess',
    },
  ]

  ranks = [
    {
      topImage: '/assets/careers/1.png',
      name: 'Level 1',
      description: 'If you have no experience in streaming or content creation at all, we’re happy to help you out! Create your own Gaming Video Creator page <a class="creatorLink" href="https://www.facebook.com/gaming/pages/create?ref=rumbleroyale" target="_blanl">here</a>, then apply now to jumpstart your journey to LVL 99!',
      buttonText: 'Learn How',
      buttonAction: 'newbie',
      perks: [
        'Access to RUMBLE ROYALE’s beginner educational video series',
        '<ul class="subList"><li> - How to live stream</li><li> - How to make video content</li><li> - How to increase your audience</li></ul>'
      ]
    },
    {
      topImage: '/assets/careers/99.png',
      name: 'Level 99',
      description: 'If you’ve already been making videos or streaming for a while and want to rank up, you’ll get bigger and better benefits!',
      buttonText: 'Apply Now',
      buttonAction: '99',
      perks: [
        'RR GREY “LVL99” CARD',
        'Discounts on RR Partner Establishments<br/><br/>',
        'Discount on Streaming Gear and PC Tech<br/><br/>',
        'Access to special events and parties<br/><br/>',
        'Game Over PH 5% Discount',
        'STRONGHOLD Access<br/><br/><br/>',
        'Own merch Store',
        'RR ASSET PACKS (Streamer HUDS, Cover photos, etc.)',
        'Access to RUMBLE ROYALE\'s Advance educational video series',
        'Access to RR content creator seminar series (shoutcasting 101, how to be a streamer, etc.)',
      ]
    },
    {
      topImage: '/assets/careers/partner.png',
      name: 'Partner',
      description: 'Once you’ve established yourself well in the gaming scene, you’ll be joining the ranks as a full-fledged partner! This comes with a whole host of benefits.',
      buttonText: 'Apply Now',
      buttonAction: 'partner',
      perks: [
        'RR GOLD PARTNER CARD',
        'Discounts on RR Partner Establishments and Companies',
        'Bigger Discount on Streaming PC, PC Gear and Tech',
        'Access to exclusive RR Party and Events exclusive to PARTNERS only.',
        'Game Over PH 10 (10% Affiliation Bonus)',
        'Rina and Boss 50% off services',
        'STRONGHOLD Priority (Streaming PODS, Strong Lounge, Sleep Pods, Birthday celebration venue space)',
        'Own merch store',
        'RR Jackets and Merch',
        'RR Website inclusion',
        'Talent Consultation',
        'Marketing Consultation',
      ]
    },
  ]

  staffPerks = [
    {
      img: '/assets/careers/food.png',
      name: 'Free Food',
      description: 'Lunch and Dinner is on us! We have a personalized chef who fills us with the heartiest meals everyday!'
    },
    {
      img: '/assets/careers/lodging.png',
      name: 'Free Lodging',
      description: 'You are free to stay and live in our state-of-the-art streamer park / home-base / workspace / gamespace called "STRONGHOLD"'
    },
    {
      img: '/assets/careers/shirt.png',
      name: 'Zero Dress Code',
      description: 'No you can’t be naked in RR but you can wear whatever you want! Be in Cosplay everyday, or in your favorite swimwear, we won’t judge. Here in RR we let you wear who you want to be.'
    },
    {
      img: '/assets/careers/coins.png',
      name: 'Collective Cut',
      description: 'Get the most out of what you do, be rewarded like never before! Here in RR, everyone gets a piece of the pie! As soon as you unlock the achievement of becoming “regularized”, you’ll automatically be included in the list of people who will get a pretty sizeable portion of the team’s quarterly earnings!'
    },
    {
      img: '/assets/careers/clock.png',
      name: 'Pick your own schedule',
      description: 'Keep on waking up late? Hate mornings? Or just want to avoid the rush hour? You can choose to either work at 10am if you love mornings or 3PM if you are the nocturnal type! RR caters to both!'
    },
    {
      img: '/assets/careers/team.png',
      name: 'Be a Big Part of the Community',
      description: 'Be around awesome people every time! From your own RR team, to talents, streamers, celebrities, esports personalities and the RUMBLE ROYALE community, feel the genuine belongingness of being part of the RR Collective!'
    },
  ]

  openExternal(linkToOpen) {
    switch (linkToOpen) {
      case 'partner':
        window.open('https://forms.gle/RWc6FFcR5fgWh4LHA')
        break
      case '99':
        window.open('https://forms.gle/zRHFyqxtSGWn8Y7X9')
        break
      case 'newbie':
        this.showOverLay = true
        break
      case 'staff':
        window.open('https://docs.google.com/forms/d/1HyjEfU2lVaDOmKNyu-kx2-w4N8KQG2YAGdONX5ILQgo')
        break
    }
  }

  setSelectedTalent(talent: Talent) {
    this.selectedTalent = talent
    return false
  }

  removeSelectedTalent(event: any) {
    this.selectedTalent = null
  }
}
