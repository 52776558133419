import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class SideBarService {

  private sideText = 'Reign Supreme'
  private showSideBar = false
  private routeList = [

  ]
  constructor() { }

  getSideText() {
    return this.sideText
  }

  getShowSideBar() {
    return this.showSideBar
  }

  updateSideText(value) {
    this.sideText = value
  }
}
