import { Component, OnInit } from '@angular/core'
import { SideBarService } from 'app/shared/services/side-bar.service'

@Component({
  selector: 'rr-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  copy = `© 2019`
  corpName = `Rumble Royale`
  highlight = ''


  constructor(private sbs: SideBarService) {
    
  }

  ngOnInit() {
  }

  getHighlight() {
    return this.sbs.getSideText()
  }

  getHasSideBar() {
    
  }

}
