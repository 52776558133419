import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'rr-gears',
  templateUrl: './gears.component.html',
  styleUrls: ['./gears.component.scss']
})
export class GearsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  gearItems = [
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
    {
      name: 'Nicollo Francisco',
      position: 'Graphic Artist',
      image: 'assets/gears/koj.png'
    },
  ]

}
