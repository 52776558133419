import { Talent } from 'app/shared/models/talent'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'talentSearch'
})
export class TalentSearchPipe implements PipeTransform {

  transform(talents: Talent[], search: string): Talent[] {
    // Check if is not null
    if (!talents || !search) return talents

    return talents.filter(
      talent => talent.streamer_name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
  }
}
