import { Component, OnInit } from '@angular/core'
import { EventService } from 'app/shared/services/event.service'
import { Papa } from 'ngx-papaparse'

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  eventsSummary = []
  eventCtr = 0
  eventYears = []
  currentSection = '2019'
  search = ''

  constructor(
    private es: EventService,
    private papa: Papa,
  ) { }

  ngOnInit() {
    this.eventYears = []
    this.eventsSummary = []
    this.eventCtr = 0
    this.currentSection = '2019'

    this.es.getEventList().subscribe(csvData => {
      this.papa.parse(csvData,{
        complete: (result) => {
          this.es.parseCSVData(result.data)
          this.eventsSummary = this.es.getParsedEvent()

          this.eventCtr = this.es.getEventCount()

          this.eventsSummary.map((summary) => {
            this.eventYears.push(summary.year)
          })

          this.eventYears.reverse()
        }
      })
    })
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId
  }

  scrollTo(section) {
    document.querySelector('#' + section)
    .scrollIntoView()
  }

  goExternal(externalUrl) {
    window.open(externalUrl)

    return false
  }

  getCurrentEvents () {
    this.eventCtr = this.eventsSummary
      .reduce((acc, summary) => acc.concat(summary.events),[])
      .filter((rrEvent) => rrEvent.name.toLowerCase().concat(rrEvent.description.toLowerCase()).indexOf(this.search.toLowerCase()) !== -1)
      .reduce((acc, event) => acc + 1,0)
  }
}
