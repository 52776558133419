import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class SocMedService {

  private fbLink: string = null
  private twLink: string = null
  private ytLink: string = null
  private igLink: string = null


  constructor(
    private http: HttpClient
  ) { }

  getUrlData(url: string, socialMedia = null) {

    let embedLink = null

    switch(socialMedia) {
      case 'FB':
        this.fbLink =  `<iframe src="https://www.facebook.com/plugins/like.php?href=${url}&width=50&layout=button_count&action=like&size=large&show_faces=true&share=false&height=25&appId" width="100" height="41" style="border:noneoverflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>`
        break
      case 'TW':
        let twitterName = url.split('/').pop()
        this.twLink = `<iframe allowtransparency="true" frameborder="0" scrolling="no" src="//platform.twitter.com/widgets/follow_button.html#dnt=false&id=twitter-widget-6&lang=en&screen_name=${twitterName}&show_count=true&show_screen_name=false&size=l" style="width:300px height:40px"></iframe>`
        break
      case 'YT':
        let youtubeChannel = url.split('/').pop()
        this.ytLink = `<iframe allowtransparency="true" frameborder="0" scrolling="no" src="//www.youtube.com/subscribe_embed?channelid=${youtubeChannel}" style="width:300px height:40px"></iframe>`
    }

    return
  }

  getEmbed(property: string) {
    return this[property]
  }

}