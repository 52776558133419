import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  inquiry = {
    name: null,
    mail: null,
    subject: '',
    message: null
  }

  subjectMatter = [
    `Inquiries`,
    `Talent Aquisition`,
    `Job Application`,
    `Project Proposal`,
  ]

  constructor(private http: HttpClient) {

  }

  ngOnInit() {
  }

  invalidData(obj) {
    for(var key in obj) {
      if(obj[key] === null || obj[key] === '')
        return true
    }
    return false
  }

  sendInquiry() {

    if(this.invalidData(this.inquiry)) {
      alert('Please fill in all details')
      return false
    }

    let body = new FormData()
    Object.keys(this.inquiry).map((key) => body.append(key, this.inquiry[key]))

    this.http
      .post('/mail.php', body)
      .subscribe((res) => {
        this.inquiry = {
          name: null,
          mail: null,
          subject: '',
          message: null
        }

        alert('Thanks for submitting!')
      })
  }

}
