import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, HostListener  } from '@angular/core'
import { Location } from "@angular/common"
import { Router, NavigationStart } from "@angular/router"
import { CsvService } from 'app/shared/services/csv.service'
import { SocMedService } from 'app/shared/services/soc-med.service'
import { Talent } from 'app/shared/models/talent'
import { Papa } from 'ngx-papaparse'

@Component({
  selector: 'rr-talent-description',
  templateUrl: './talent-description.component.html',
  styleUrls: ['./talent-description.component.scss']
})
export class TalentDescriptionComponent implements OnInit {

  constructor(
    private cs: CsvService,
    private papa: Papa,
    private sm: SocMedService,
    private location: Location,
    private router: Router
  ) {
    router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        if (location.path() != "") {
          const profileToLoad = location.path().replace('/talents/','')
          this.profile = this.cs.getTalent(profileToLoad)
        } else {
        }

        this.stopCarousel()
      }
    })
  }

  screenWidth = window.innerWidth
  private carousel = null
  private code = null
  @Input('talent') profile: Talent
  @Output() closeProfile: EventEmitter<any> = new EventEmitter()
  private currentPhoto = ''
  private photoIndex = 0
  private photoCarousel: [Talent]
  private carouselLength = null
  private carouselRunning = null
  private descriptionOverLay = false

  ngOnInit() {
    console.log('Description Loaded')
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.profile) {
      this.stopCarousel()
      this.currentPhoto = this.profile.photos[this.photoIndex]
      this.photoCarousel = this.profile.photos
      this.carouselLength = this.profile.photos.length

      if(this.profile.facebook_link) {
        this.sm.getUrlData(this.profile.facebook_link,'FB')
      }
      if(this.profile.twitter_link) {
        this.sm.getUrlData(this.profile.twitter_link,'TW')
      }
      if(this.profile.youtube_link) {
        this.sm.getUrlData(this.profile.youtube_link,'YT')
      }
      this.runCarousel()
    }
  }

  getLink(socMed: string) {
    const retVal = this.sm.getEmbed(socMed)
    return `${retVal}`
  }

  setPhoto(index) {
    this.currentPhoto = this.profile.photos[index]
  }

  nextPhoto() {
    this.photoIndex++
    if (this.profile.photos.length == this.photoIndex) { this.photoIndex = 0 }
    this.currentPhoto = this.profile.photos[this.photoIndex]
  }

  goToPic(index) {
    this.photoIndex = index
    this.currentPhoto = this.profile.photos[this.photoIndex]
  }

  isSelected(index) {
    return index === this.photoIndex
  }

  prevPhoto() {
    this.photoIndex--
    if (this.photoIndex == -1) { this.photoIndex = this.carouselLength -1 }
    this.currentPhoto = this.profile.photos[this.photoIndex]
  }

  runCarousel() {
    if(this.carouselRunning === 0) {
      this.carouselRunning = setInterval(() => {
        this.nextPhoto()
      }, 3500)
    }
  }

  stopCarousel() {
    if(this.carouselRunning !== 0) {
      clearInterval(this.carouselRunning)
      this.carouselRunning = 0
    }
  }

  removeProfile(e) {
    this.stopCarousel()
    this.closeProfile.emit(e)
  }

  showDescriptionOverLay() {
    this.descriptionOverLay = true
  }

  hideDescriptionOverLay() {
    this.descriptionOverLay = false
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth
  }
}
